/*
    Author : Adrien
    Created at : 15/04/2024
    Description : Navigation
*/

import { useLocation, Link } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";
import {
    Sidebar,
    SubMenu,
    Menu,
    MenuItem
  } from "react-pro-sidebar";

// import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import LogoWhite  from '../assets/images/logo_white.png';
import ImageWithLoader from './ImageWithLoader';
import Logo  from '../assets/images/logo.png';


export const NavSidebar = () => {

    const [collapsed, setCollapsed] = useState(false);
    const location = useLocation();
    const [toggled, setToggled] = useState(false);
    const [mobileMenu, setMobileMenu] = useState(false);
    const [isOpen, setIsOpen] = useState([location.pathname]);

    const handleCollapsedChange = () => {
      setCollapsed(!collapsed);
    };

    const handleCollapsedChangeMobile = useCallback(async () => {
        setMobileMenu(!mobileMenu)
        setCollapsed(false);
    },[mobileMenu]) 

    const handleToggleSidebar = (value) => {
      setToggled(value);
    };

    const handleOpenMenu = (value) => {
        const path = value ?? location.pathname

        if(isOpen[0] === path){
            setIsOpen(["default"]); 
        } else {
            setIsOpen([path]);  
        }
    }

    useEffect(() => {
        // Automatically close menu for mobile after navigation 
        // if(window.innerWidth < 991){
        //     handleCollapsedChangeMobile()
        // }
    }, []);

    return (
        <div>
            <div onClick={handleCollapsedChangeMobile} className="mobileMenu"><i className="fi fi-ss-menu-burger"></i></div> 
            <Sidebar
                breakPoint={1000}
                className={`sidebar app ${toggled ? "toggled" : ""} ${mobileMenu ? 'mobileSidebar' : ''}`}
                backgroundColor="white"
                style={{ height: "100%"}}
                collapsed={collapsed}
                toggled={true}
                handleToggleSidebar={handleToggleSidebar}
                handleCollapsedChange={handleCollapsedChange}
            >
                <main>
                    <Menu>
                        {collapsed ? (
                            <div onClick={handleCollapsedChange} className="logo_navbar d-flex justify-content-around align-items-center">
                                    <i className="fi fi-ss-angle-right"></i>
                            </div>
                        ) : (
                            <div onClick={handleCollapsedChange} className="logo_navbar d-flex justify-content-around align-items-center">
                                    <div className="d-flex justify-content-center align-items-center">
                                    <ImageWithLoader
                                        src={localStorage.getItem("theme") ? (localStorage.getItem("theme") === "light" ? Logo : LogoWhite) : Logo}
                                        alt=""
                                        style={{width: 150}}
                                    /> 
                                    {/* <img width={150} src={localStorage.getItem("theme") ? (localStorage.getItem("theme") === "light" ? Logo : LogoWhite) : Logo} alt=""/> */}
                                </div>
                                <i className="fi fi-ss-angle-left"></i>
                            </div>
                        )}
                        <hr />
                    </Menu>

                    <Menu
                        menuItemStyles={{
                            button: {
                            // the active class will be added automatically by react router
                            // so we can use it to style the active menu item
                            [`&.active`]: {
                                backgroundColor: 'blue',
                                color: 'blue',
                            },
                            },
                        }}
                        renderExpandIcon={({open}) => open ? <i className="expand_icon fi fi-rr-angle-small-up"/> : <i className="expand_icon fi fi-rr-angle-small-down"/>}
                    >
                    <MenuItem onClick={() => handleOpenMenu('home')} active={location.pathname === "/home" ? true : false} icon={location.pathname === "/home" ? <i className="fi-sr-home"/> : <i className="fi-rr-home"/>} component={<Link to="/home"/>}>Dashboard</MenuItem>
                    <SubMenu 
                        onClick={() => handleOpenMenu('training')}
                        defaultOpen={location.pathname.includes("training")}
                        open={isOpen[0].includes("training")}
                        icon=
                            {
                                location.pathname.includes("training") ? <i className="active_icon fi-sr-book-alt"/> : <i className="fi-rr-book-alt"/>
                            } 
                            label={"Formations"}
                        >
                        <MenuItem active={location.pathname.includes("training/list") || location.pathname.includes("training/detail")} component={<Link to="/training/list"/>}>Liste des formations</MenuItem>
                        <MenuItem active={location.pathname.includes("training/calendar")} component={<Link to="/training/calendar"/>}>Calendrier de publications</MenuItem>
                    </SubMenu>
                    <MenuItem onClick={() => handleOpenMenu('ambassadress')} active={location.pathname.includes("ambassadress")} icon={location.pathname.includes("ambassadress") ? <i className="fi-sr-user"/> : <i className="fi-rr-user"/>} component={<Link to="/ambassadress"/>}>Formatrices</MenuItem>
                    <MenuItem onClick={() => handleOpenMenu('users')} active={location.pathname.includes("users") && !location.pathname.includes("moderation")} icon={location.pathname.includes("users") && !location.pathname.includes("moderation") ? <i className="fi-sr-graduation-cap"/> : <i className="fi-rr-graduation-cap"/>} component={<Link to="/users"/>}>Utilisateurs</MenuItem>
                    <SubMenu 
                        onClick={() => handleOpenMenu('moderation')}
                        defaultOpen={location.pathname.includes("moderation")}
                        open={isOpen[0].includes("moderation")}
                        icon=
                            {
                                location.pathname.includes("moderation") ? <i className="active_icon fi fi-sr-flag-alt"/> : <i className="fi fi-rr-flag-alt"></i>
                            } 
                            label={"Modération"}
                        >
                        <MenuItem active={location.pathname.includes("comments")} component={<Link to="/moderation/comments"/>}>Commentaires masqués</MenuItem>
                        <MenuItem active={location.pathname.includes("moderation/users")} component={<Link to="/moderation/users"/>}>Élèves signalés</MenuItem>
                        <MenuItem active={location.pathname.includes("reports")} component={<Link to="/moderation/reports"/>}>Signalements</MenuItem>
                    </SubMenu>
                    <MenuItem onClick={() => handleOpenMenu('subscriptions')} active={location.pathname.includes("subscriptions")} icon={location.pathname.includes("subscriptions") ? <i className="fi-sr-layout-fluid"/> : <i className="fi-rr-layout-fluid"/>} component={<Link to="/subscriptions"/>}>Abonnements</MenuItem>
                    <MenuItem onClick={() => handleOpenMenu('statistics')} active={location.pathname.includes("statistics")} icon={location.pathname.includes("statistics") ? <i className="fi fi-sr-chart-simple"/> : <i className="fi fi-tr-chart-simple"/>} component={<Link to="/statistics"/>}>Statistiques</MenuItem>
                    <SubMenu 
                        onClick={() => handleOpenMenu('setting')}
                        defaultOpen={location.pathname.includes("setting")}
                        open={isOpen[0].includes("setting")}
                        icon=
                            {
                                location.pathname.includes("setting") ? <i className="active_icon fi-sr-settings"/> : <i className="fi-rr-settings"/>
                            } 
                            label={"Paramètres"}
                        >
                        <MenuItem active={location.pathname.includes("category")} component={<Link to="/setting/category"/>}>Catégories</MenuItem>
                        <MenuItem active={location.pathname.includes("terms")} component={<Link to="/setting/terms"/>}>Glossaire</MenuItem>
                        <MenuItem active={location.pathname.includes("products")} component={<Link to="/setting/products"/>}>Produits</MenuItem>
                    </SubMenu>
                  
                    </Menu>
                </main>
            </Sidebar>
        </div>
    );
};