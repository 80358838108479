import { BrowserRouter, Routes, Route, Navigate  } from "react-router-dom";
import { AuthProvider, useAuth } from './utils/AuthContext';
import { ToastProvider } from './utils/ToastProvider';
import { NavSidebar } from "./components/NavSidebar";
import ClipLoader from "react-spinners/ClipLoader";
import React, { Suspense } from 'react';
import './assets/css/App.css';

const AmbassadressDetail = React.lazy(() => import('./pages/AmbassadressDetail'));
const TrainingCalendar = React.lazy(() => import('./pages/Training/TrainingCalendar'));
const ForgotPassword = React.lazy(() => import('./pages/ForgotPassword'));
const TrainingDetail = React.lazy(() => import('./pages/Training/TrainingDetail'));
const UserDetail = React.lazy(() => import('./pages/User/UserDetail'));
const Category = React.lazy(() => import('./pages/Settings/Category/Category'));
const Ambassadress = React.lazy(() => import('./pages/Ambassadress'));
const Subscription = React.lazy(() => import('./pages/Subscription'));
const Terms = React.lazy(() => import('./pages/Settings/Terms'));
const Statistic = React.lazy(() => import('./pages/Statistic'));
const Training = React.lazy(() => import('./pages/Training/Training'));
const User = React.lazy(() => import('./pages/User/User'));
const Login = React.lazy(() => import('./pages/Login'));
const Home = React.lazy(() => import('./pages/Home'));
const Reports = React.lazy(() => import('./pages/Moderation/Reports'));
const Comments = React.lazy(() => import('./pages/Moderation/Comments'));
const ReportUser = React.lazy(() => import('./pages/Moderation/ReportUser'));
const ResetPasswordApp = React.lazy(() => import('./pages/ResetPasswordApp'));

function App() {

  // For light and dark theme mode
  document.body.classList.add(localStorage.getItem("theme") ?? "light");

  return (
    <ToastProvider>
      <AuthProvider>
        <div>
          <Suspense fallback={
              <div className="h-100 w-100 loader d-flex justify-content-center align-items-center">
                <ClipLoader
                    color={"#E8768A"}
                    loading={true}
                    size={25}
                />
              </div>
          }>
            <Content/>
          </Suspense>
        </div>
      </AuthProvider>
    </ToastProvider>
  );
}

function Content() {
  const { isLoggedIn } = useAuth();

  return (
    <div>
      {isLoggedIn ? (
        <BrowserRouter>
            <NavSidebar/>
              <Routes>
                <Route path="/" element={<Navigate to="/home" />} />
                <Route path="home" element={<Home />} />
                <Route path="training/list" element={<Training />} />
                <Route path="training/detail/:id" element={<TrainingDetail />} />
                <Route path="training/calendar" element={<TrainingCalendar />} />
                <Route path="ambassadress" element={<Ambassadress />} />
                <Route path="ambassadress/detail/:id" element={<AmbassadressDetail />} />
                <Route path="users" element={<User />} />
                <Route path="users/detail/:id" element={<UserDetail />} />
                <Route path="moderation/comments" element={<Comments />} />
                <Route path="moderation/users" element={<ReportUser />} />
                <Route path="moderation/reports" element={<Reports />} />
                <Route path="subscriptions" element={<Subscription />} />
                <Route path="statistics" element={<Statistic />} />
                <Route path="setting/category" element={<Category />} />
                <Route path="setting/terms" element={<Terms />} />

                {/* Redirection pour toutes les autres routes non définies */}
                <Route path="*" element={<Navigate to="/home" />} />
              </Routes>
        </BrowserRouter>
      ) : (
        <BrowserRouter>
          <Routes>
              <Route path="/" element={<Navigate to="/login" />} />
              <Route path="login" element={<Login />} />
              <Route path="forgot-password" element={<ForgotPassword />} />

              {/* Redirection pour toutes les autres routes non définies */}
              <Route path="*" element={<Navigate to="/login" />} />
              <Route path="reset-password" element={<ResetPasswordApp />} />
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
