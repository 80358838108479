import React, { createContext, useContext, useRef } from 'react';
import { Toast } from 'primereact/toast';

const ToastContext = createContext();

export const useToast = () => useContext(ToastContext);

export const ToastProvider = ({ children }) => {
    const toastRef = useRef(null);

    const showToast = (severity, summary, detail, life = 3000) => {
        if (toastRef.current) {
            toastRef.current.show({ severity, summary, detail, life });
        }
    };

    return (
        <ToastContext.Provider value={{ showToast }}>
            {children}
            {/* Render PrimeReact Toast component */}
            <Toast ref={toastRef}></Toast> 
        </ToastContext.Provider>
    );
};